var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('h2',{staticClass:"form__title"},[_vm._v(_vm._s(_vm.$t('signIn.title')))]),_c('ValidationProvider',{attrs:{"rules":"required|email","name":_vm.$t('form.login')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var touched = ref.touched;
var changed = ref.changed;
return [_c('Input',{staticClass:"form__input",attrs:{"type":"email","label":_vm.$t('form.login'),"placeholder":_vm.$t('form.login'),"persistent-placeholder":"","error-messages":(invalid && touched || invalid && !changed) ? errors[0] : null,"data-test-id":"login-input","maxlength":"320"},model:{value:(_vm.user.login),callback:function ($$v) {_vm.$set(_vm.user, "login", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.login"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{staticClass:"form__input",attrs:{"type":_vm.showPassword ? 'text' : 'password',"placeholder":_vm.$t('form.password'),"error-messages":errors[0],"data-test-id":"password-input","maxlength":"255"},scopedSlots:_vm._u([{key:"appendIcon",fn:function(){return [_c('v-icon',{staticClass:"show-password",attrs:{"color":"primary"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_vm._v(_vm._s(_vm.showPassword ? '$closeEyeIcon' : '$eyeIcon'))])]},proxy:true}],null,true),model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}),_c('div',{staticClass:"form__forgot",on:{"click":function($event){_vm.isResetPasswordActive = true}}},[_vm._v(_vm._s(_vm.$t('signIn.forgot')))]),_c('Button',{staticClass:"form__button",attrs:{"color":"primary","block":"","type":"submit","data-test-id":"signin-button"}},[_vm._v(_vm._s(_vm.$t('signIn.button')))]),_c('v-checkbox',{staticClass:"form__remember",attrs:{"label":_vm.$t('form.remember')},model:{value:(_vm.user.rememberMe),callback:function ($$v) {_vm.$set(_vm.user, "rememberMe", $$v)},expression:"user.rememberMe"}}),_c('div',{staticClass:"form__change-role mt-3 d-flex flex-column"},[_c('span',[_vm._v(_vm._s(_vm.$t('signIn.changeRole.text')))]),_c('a',{staticClass:"link primary--text",attrs:{"href":_vm.landlordApp}},[_vm._v(_vm._s(_vm.$t('signIn.changeRole.link')))])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }