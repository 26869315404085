var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reset-password"},[_c('Drawer',{attrs:{"maxWidth":"390","closeBtn":false},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"reset-password-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-container',{staticClass:"mt-sm-8"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column pb-0",attrs:{"xs":"12"}},[_c('p',{staticClass:"reset-password-title text-body-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('form.forgotPassword.title')))]),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var touched = ref.touched;
var changed = ref.changed;
return [_c('Input',{attrs:{"type":"email","label":_vm.$t('form.forgotPassword.input-placeholder'),"error-messages":(invalid && touched || invalid && !changed) ? errors[0] : null,"maxlength":"320","data-test-id":"email-forgot-password"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-nowrap pt-0",attrs:{"justify":"space-between","align":"start"}},[_c('div',{staticClass:"reset-password-icon"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$infoIcon")])],1),_c('span',{staticClass:"reset-password-text text-body-2"},[_vm._v(_vm._s(_vm.$t('form.forgotPassword.information')))])])],1),_c('v-row',[_c('v-col',{staticClass:"reset-password-buttons"},[_c('Button',{staticClass:"reset-password-cancel",attrs:{"color":"secondary"},on:{"click":function($event){_vm.isResetPasswordActive = false}}},[_vm._v(_vm._s(_vm.$t('form.forgotPassword.cancel')))]),_c('Button',{attrs:{"type":"submit","color":"primary","data-test-id":"send-link-button"}},[_vm._v(_vm._s(_vm.$t('form.forgotPassword.send')))])],1)],1)],1)],1)]}}])})]},proxy:true}]),model:{value:(_vm.isResetPasswordActive),callback:function ($$v) {_vm.isResetPasswordActive=$$v},expression:"isResetPasswordActive"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }