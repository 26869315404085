






















































import {Component, Vue} from 'vue-property-decorator';
import {createHelpers} from 'vuex-map-fields';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import '@/validators/vee-validate';

const {mapFields} = createHelpers({
  getterType: 'getUiFields',
  mutationType: 'updateUiFields',
});

@Component({
  components: { ValidationObserver, ValidationProvider },
  computed: { ...mapFields(['isResetPasswordActive']) }
})
export default class SignInResetPassword extends Vue {
  private email: string = '';
  private isResetPasswordActive!: boolean;

  private onSubmit() {
    this.$store.dispatch('resetPassword', this.email)
      .then(() => {
        this.$notify({
          group: 'notification',
          type: 'notification-success',
          text: this.$tc('form.forgotPassword.success-message'),
        });
        this.isResetPasswordActive = false;
      })
      .catch(() => {
        this.$notify({
          group: 'notification',
          type: 'notification-error',
          text: this.$tc('form.forgotPassword.error-message') as string,
        });
      });
  }
}
