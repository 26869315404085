












import { Component, Vue } from 'vue-property-decorator';
import SignInSidebar from '@/pages/SignIn/SignIn.sidebar.vue';
import SignInResetPassword from '@/pages/SignIn/SignIn.resetPassword.vue';
import SignInNewPassword from '@/pages/SignIn/SignIn.newPassword.vue';
import {createHelpers} from 'vuex-map-fields';
import {i18n} from '@/main';
import {EnvProvider} from '@/utilities';

const {mapFields} = createHelpers({
  getterType: 'getUiFields',
  mutationType: 'updateUiFields',
});

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.signIn.title'),
      meta: [
        {
          name: 'description',
          content: i18n.tc('meta.signIn.description')
        }
      ]
    };
  },
  components: {
    SignInSidebar,
    SignInResetPassword,
    SignInNewPassword
  },
  computed: { ...mapFields(['isNewPasswordActive']) }
})
export default class SignInPage extends Vue {
  private companyName: string = EnvProvider('TITLE');
  private isNewPasswordActive!: boolean;

  private get copyrightText(): string {
    return ' &#169; 2021. ' + this.$t('footer.allRights');
  }

  public mounted() {
    if (this.$route.query.token) {
      this.$store.commit('SET_CONFIRM_RESET_PASSWORD_TOKEN', this.$route.query.token);
      this.isNewPasswordActive = true;
    }
  }
}
