



























































import {Component, Vue} from 'vue-property-decorator';
import {createHelpers} from 'vuex-map-fields';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import '@/validators/vee-validate';

const {mapFields} = createHelpers({
  getterType: 'getUiFields',
  mutationType: 'updateUiFields',
});

@Component({
  components: { ValidationObserver, ValidationProvider },
  computed: { ...mapFields(['isNewPasswordActive']) }
})
export default class SignInNewPassword extends Vue {
  private password: string = '';
  private reEnteredPassword: string = '';
  private isNewPasswordActive!: boolean;

  private onSubmit() {
    this.$store.dispatch('confirmResetPassword', {
      password: this.password,
      reEnteredPassword: this.reEnteredPassword,
      token: this.$store.getters.getConfirmResetPasswordToken,
    }).then(() => {
      this.$notify({
        group: 'notification',
        type: 'notification-success',
        text: this.$tc('form.newPassword.success-message'),
      });
      this.isNewPasswordActive = false;
    });
  }
}
