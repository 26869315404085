















import { Component, Vue } from 'vue-property-decorator';
import SidebarForm from '@/pages/SignIn/SignIn.form.vue';
import LanguageCountrySwitcher from '@/components/LanguageCountrySwitcher.vue';
import {EnvProvider} from '@/utilities';

@Component({
  components: {
    LanguageCountrySwitcher,
    SidebarForm
  }
})
export default class SignInSidebar extends Vue {
  private getWebSiteLink: string = EnvProvider('URL_WEBSITE');
}
