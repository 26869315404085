





























































import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import '@/validators/vee-validate';
import {IAuthCredentials} from '@/interfaces';
import {createHelpers} from 'vuex-map-fields';
import { CookieService } from '@/services/data/cookie.service';
import {EnvProvider} from '@/utilities';
import {LocaleService} from '@/utilities/locale';

const {mapFields} = createHelpers({
  getterType: 'getUiFields',
  mutationType: 'updateUiFields',
});

@Component({
  components: { ValidationObserver, ValidationProvider },
  computed: { ...mapFields(['isResetPasswordActive']) }
})
export default class SignInForm extends Vue {
  private isResetPasswordActive!: boolean;
  private showPassword = false;
  private landlordApp: string = LocaleService.replaceDomain(EnvProvider('URL_LANDLORD_APP'));

  private user: IAuthCredentials = {
    login: '',
    password: '',
    rememberMe: false
  };

  private login(): void {
    this.$store.dispatch('logIn', this.user)
      .then(() => this.redirect())
      .catch(() => {
       if (this.$store.getters.getSignInErrorData) {
          return this.$notify({
            group: 'notification',
            type: 'notification-error',
            text: this.$tc('signIn.logIn-error')
          });
        }
      });
  }

  private redirect(): any {
    return !CookieService.get('tenantIntroCompleted')
      ? this.$router.push({ name: 'intro' })
      : this.$store.getters.getSignInRedirectUrl
        ? (
          window.location.href = this.$store.getters.getSignInRedirectUrl,
            this.$store.commit('SET_SIGN_IN_REDIRECT_URL', '')
        )
        : this.$router.push({ name: 'home' });
  }
}
