










































import { Component, Vue } from 'vue-property-decorator';
import { Language, LanguageNameEnum } from '@/interfaces';
import { getIconByLanguage } from '@/dictionaries/icons.language.dictionary';
import { ILocaleSettings } from '@/services/api/locale.service';
import { LocaleService } from '@/utilities/locale';
import { EnvProvider } from '@/utilities';

@Component
export default class LanguageCountrySwitcher extends Vue {
  public getIconByLanguage = getIconByLanguage;
  public languageName = LanguageNameEnum;
  private isSwitcherActive = false;

  private onItemClick({ country }: ILocaleSettings, language: Language) {
    const currentLang = this.$route.params.lang;
    const currentCountry = this.$store.getters.getCurrentSettings.country;

    if (language !== currentLang || currentCountry !== country) {
      const host = LocaleService.replaceDomain(EnvProvider('URL'), country);
      const path = this.$route.fullPath.replace(`/${currentLang}/`, `/${language}/`);

      window.location.href = `${host}${path}`;
    }
  }
}
